<template>
  <dialogComp title="新增人员" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <radioFormComp
        :disabled="btnType === 'singleAdd'"
        label="是否工作日:"
        prop="working"
        :radioList="workingList"
        :hval="formData.working"
        @change="workingChange"
      />
      <el-form-item label="日期:" prop="schedulingDate">
        <el-date-picker
          :disabled="btnType === 'singleAdd'"
          :picker-options="{ firstDayOfWeek: 1 }"
          v-model="formData.schedulingDate"
          type="date"
          size="small"
          placeholder="请选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <selectFormComp
        label="排班:"
        prop="type"
        :disabled="!formData.working || btnType === 'singleAdd'"
        placeholder="请选择排班"
        :data="data"
        :hval="formData.type"
        @change="typeChange"
      />
      <el-form-item
        v-if="btnType === 'batchAdd'"
        label="售后人员:"
        prop="afterSaleIds"
      >
        <handleUser
          placeholder="请选择售后人员"
          :hval="formData.afterSaleIds"
          businessRole="aftermarket"
          @change="afterSaleIdsChange"
          multiple
        />
      </el-form-item>
      <el-form-item v-else label="售后人员:" prop="afterSaleId">
        <handleUser
          placeholder="请选择售后人员"
          :hval="formData.afterSaleId"
          businessRole="aftermarket"
          @change="afterSaleIdChange"
        />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>
<script>
import handleUser from '@/views/components/handleUser'
import { dutyBatchAdd, dutyAdd } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: {
    handleUser
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    },
    btnType: {
      type: String,
      default: 'batchAdd'
    }
  },
  data () {
    return {
      workingList: [
        { label: '工作日', value: true },
        { label: '非工作日', value: false }
      ],
      data: [
        { label: '早班', value: 1 },
        { label: '午班', value: 2 },
        { label: '晚班', value: 3 }
      ],
      formData: {
        working: true,
        schedulingDate: '',
        type: '',
        afterSaleId: '',
        afterSaleIds: []
      },
      rules: {
        schedulingDate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        type: [{ required: true, message: '请选择排班', trigger: 'change' }],
        afterSaleIds: [
          { required: true, message: '请选择售后人员', trigger: 'change' }
        ],
        afterSaleId: [
          { required: true, message: '请选择售后人员', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        console.log(this.btnType)
        if (this.btnType === 'singleAdd') {
          const { working, schedulingDate, type } = this.info
          this.formData = {
            working,
            schedulingDate,
            type,
            afterSaleId: '',
            afterSaleIds: []
          }
        } else {
          this.formData = {
            working: true,
            schedulingDate: '',
            type: '',
            afterSaleId: '',
            afterSaleIds: []
          }
        }
        this.$refs.formData && this.$refs.formData.resetFields()
      }
    }
  },
  methods: {
    workingChange (val) {
      this.formData.working = val
      // 如果是工作日  排班定死早班   非工作日可选
      if (this.formData.working) {
        this.formData.type = ''
      } else {
        this.formData.type = 1
      }
    },
    afterSaleIdsChange (val) {
      this.formData.afterSaleIds = val
    },
    afterSaleIdChange (val) {
      this.formData.afterSaleId = val
    },
    typeChange (val) {
      this.formData.type = val
    },
    close () {
      this.formData = {
        working: true,
        schedulingDate: '',
        type: '',
        afterSaleId: '',
        afterSaleIds: []
      }
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const {
            schedulingDate,
            type,
            afterSaleIds,
            working,
            afterSaleId
          } = this.formData
          const params = {
            schedulingDate: formatDate(schedulingDate),
            type,
            working
          }
          const request =
            this.btnType === 'batchAdd'
              ? dutyBatchAdd({ ...params, afterSaleIds })
              : dutyAdd({ ...params, afterSaleId })
          request.then(res => {
            this.$message.success('新增成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor {
  width: 100%;
}
</style>
