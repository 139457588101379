<template>
  <div class="layout_common roles_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <div>自动排班:</div>
            <el-switch
              v-model="autoScheduling"
              active-text="是"
              inactive-text="否"
              @change="autoSchedulingChange"
            >
            </el-switch>
            <div>排班日期:</div>
            <el-date-picker
              v-model="day"
              type="week"
              format="yyyy 第 WW 周"
              size="small"
              :picker-options="{ firstDayOfWeek: 1 }"
              placeholder="选择周"
              @change="dayChange"
              :clearable="false"
            >
            </el-date-picker>
            <btnComp @click="add('batchAdd')" v-permission="'新增'">新增</btnComp>
          </div>
        </div>
        <div class="duty_table">
          <el-table
            :data="tableData"
            :header-cell-style="{
              color: 'rgba(0,0,0,0.85)',
              background: '#FAFAFA'
            }"
            style="margin-top:20px"
          >
            <el-table-column prop="type" label="排班" width="80"> </el-table-column>
            <el-table-column
              v-for="(item, index) in theadName"
              :key="index"
              :prop="item.en"
              :label="item.ch"
            >
              <template v-slot="scope">
                <template>
                  <div
                    v-for="(citem, cindex) in scope.row[item.en]"
                    :key="cindex"
                  >
                    {{ citem.afterSaleName || '-' }}
                    <i class="icon el-icon-circle-close"  v-permission="'删除'"  @click="delUser(citem)">
                    </i>
                  </div>
                  <i v-if="!handleShowBtn(scope.row,item)" v-permission="'单个新增'" class="icon el-icon-circle-plus-outline" @click="addUser('singleAdd',scope.row,item)"></i>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <add :isShow="addShow" :info="info" :btnType="btnType" @close="close" />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="sureDel">
      <p>{{ `确定删除${userInfo.afterSaleName}吗？` }}</p>
    </dialogComp>
  </div>
</template>

<script>
import add from './add'
import { dutyList, dictIsAuto, dutyUserDel } from '@/api'
import { formatDate } from '@/utils/common'
import moment from 'moment'
export default {
  components: {
    add
  },
  data () {
    return {
      hintShow: false,
      addShow: false,
      autoScheduling: false,
      day: new Date(),
      userInfo: {},
      info: {},
      btnType: '', // 用来区分是单个新增还是批量新增人员
      tableData: [
        {
          type: '早班',
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        },
        {
          type: '午班',
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        },
        {
          type: '晚班',
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        }
      ],
      theadName: [
        { en: 'monday', ch: '周一' },
        { en: 'tuesday', ch: '周二' },
        { en: 'wednesday', ch: '周三' },
        { en: 'thursday', ch: '周四' },
        { en: 'friday', ch: '周五' },
        { en: 'saturday', ch: '周六' },
        { en: 'sunday', ch: '周日' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      dutyList({ day: formatDate(this.day) }).then(res => {
        if (res.code === '1') {
          const data = res.data
          this.autoScheduling = res.data.autoScheduling
          this.handleWeek(data)
          // type排班类别：1：早班，2：午班，3：晚班
          Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
              this.tableData[0][key] = data[key]
                .filter(item => item.type === 1)
                .map(item => ({
                  afterSaleName: item.afterSaleName,
                  id: item.id
                }))
              this.tableData[1][key] = data[key]
                .filter(item => item.type === 2)
                .map(item => ({
                  afterSaleName: item.afterSaleName,
                  id: item.id
                }))
              this.tableData[2][key] = data[key]
                .filter(item => item.type === 3)
                .map(item => ({
                  afterSaleName: item.afterSaleName,
                  id: item.id
                }))
            }
          })
        }
      })
    },
    // 根据日期算出这周的日期
    handleWeek (data) {
      const {
        mondayWorking,
        tuesdayWorking,
        wednesdayWorking,
        thursdayWorking,
        fridayWorking,
        saturdayWorking,
        sundayWorking
      } = data
      const timeNow = this.day
      const weekOfday = moment(timeNow).format('E')
      this.theadName.forEach((item, index) => {
        // eslint-disable-next-line no-unused-vars
        let week = ''
        let workingCon = ''
        switch (index + 1) {
          case 1:
            week = '周一'
            workingCon = mondayWorking ? '工作日' : '非工作日'
            break
          case 2:
            week = '周二'
            workingCon = tuesdayWorking ? '工作日' : '非工作日'
            break
          case 3:
            week = '周三'
            workingCon = wednesdayWorking ? '工作日' : '非工作日'
            break
          case 4:
            week = '周四'
            workingCon = thursdayWorking ? '工作日' : '非工作日'
            break
          case 5:
            week = '周五'
            workingCon = fridayWorking ? '工作日' : '非工作日'
            break
          case 6:
            week = '周六'
            workingCon = saturdayWorking ? '工作日' : '非工作日'
            break
          case 7:
            week = '周日'
            workingCon = sundayWorking ? '工作日' : '非工作日'
            break
          default:
            break
        }
        item.ch = `${week} (${moment(timeNow)
          .subtract(weekOfday - (index + 1), 'days')
          .format('MM/DD')}) [${workingCon}]`
      })
    },
    dayChange (val) {
      this.day = val
      this.getList()
    },
    autoSchedulingChange (val) {
      dictIsAuto({ flag: val }).then(res => {
        this.$message.success('修改成功')
        this.getList()
      })
    },
    delUser (userInfo) {
      this.hintShow = true
      this.userInfo = userInfo
    },
    addUser (btnType, row, hItem) {
      this.btnType = btnType
      this.addShow = true
      this.handleAddInfo(row, hItem)
    },
    // 是否显示+按钮（非工作日并且是午班和晚班不显示）
    handleShowBtn (row, hItem) {
      // 是否显示+按钮（非工作日并且是午班和晚班不显示）
      const working = !hItem.ch.includes('非工作日')
      const type = row.type === '早班' ? 1 : row.type === '午班' ? 2 : 3
      const flag = working === false && (type === 2 || type === 3)
      // true 不显示   false 显示
      return flag
    },
    // 处理点击+新增的信息处理
    handleAddInfo (row, hItem) {
      const working = !hItem.ch.includes('非工作日')
      const schedulingDate = hItem.ch.slice(4, 9).replace('/', '-')
      const curYear = new Date().getFullYear()
      const curMonth = new Date().getMonth() + 1
      const clickMonth = schedulingDate.slice(1, 2)
      let year
      // 考虑跨年问题
      // eslint-disable-next-line eqeqeq
      if (clickMonth == '12' && curMonth == '1') {
        year = curYear - 1
      } else {
        year = curYear
      }
      const type = row.type === '早班' ? 1 : row.type === '午班' ? 2 : 3
      this.info = {
        working,
        schedulingDate: year + '-' + schedulingDate,
        type
      }
    },
    sureDel () {
      dutyUserDel({ id: this.userInfo.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    add (btnType) {
      this.btnType = btnType
      this.addShow = true
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size:18px;
  cursor:pointer;
}
.el-icon-circle-close {
  color: red;
}
.el-icon-circle-plus-outline {
  font-size:22px;
  color: #385BFF;
}
</style>
